import $ from 'jquery';
//import { getCommonAccordionOptions } from "@/js/jqueryHelpers.js";
import {
  isObjectArray
} from "@/js/helper.js";
import * as jsonpatch from 'fast-json-patch';
//import { applyOperation } from 'fast-json-patch';

/*
function getEvalContent($evalParametersContainer, evalResultRowSet, tableTitle, titleArray, operatorType, addButtonName, whatFor) {
  let evalContent = '<div class="evalResultContent"><div class="RequestAttrs tabs"><div class="ui-widget-header requestContainerHeader">' + tableTitle + '</div></div><div class="evalAttrContainer ui-widget-content ui-corner-bottom"></div></div>';
  $evalParametersContainer.html(evalContent);
  let $evalAttrContainer = $evalParametersContainer.find(".evalAttrContainer");
  getEvalContentTable($evalAttrContainer, evalResultRowSet, titleArray, operatorType, addButtonName, whatFor);
}
*/





// function getCollpsedAccordionOptions() {
//   let accordionOptions = {
//     header: '.accordionHeader',
//     autoHeight: false,
//     heightStyle: "content",
//     /*  multiple: true,
//       create: function( event, ui ) {
//           let $collpsedAttributes=ui.panel.closest(".collpsedAttributes");
//           if (inTestConfigured===false){
//             $collpsedAttributes.children(":nth-child(1)").hide();
//             $collpsedAttributes.children(":nth-child(2)").hide();
//           setTimeout(function () {
//           // for some reason the secound child is showing again so stet time out to hide it
//           $collpsedAttributes.children(":nth-child(2)").hide();
//           }, 1000);
//           }   if (showExpandable===false){
//             $collpsedAttributes.children(":nth-child(3)").hide();
//             $collpsedAttributes.children(":nth-child(4)").hide();
//           }
//         },*/
//     collapsible: true,
//     active: false

//   };
//   accordionOptions = $.extend(accordionOptions, getCommonAccordionOptions());
//   /*if (inTestConfigured ==true){
//         $.extend(true, accordionOptions, { active: 0});
//     }*/
//   // if (inTestConfigured ==false && showExpandable==true){
//   //     $.extend(true, accordionOptions, {collapsible: true});
//   //  }
//   return accordionOptions;
// }

// function checkHideAccordion($collpsedAttributes, inTestConfigured, showExpandable) {
//   if (inTestConfigured === false) {
//     $collpsedAttributes.children(".userInputAccordion").hide();


//   } else {
//     //  $collpsedAttributes.children(".userInputAccordion").accordion({ "active": 0 });
//   }
//   if (showExpandable === false) {
//     $collpsedAttributes.children(".expandableAccordion").hide();
//   }

// }





function getOperators(type, operators) {
  let requiredOperators = [];
  if (type == "calc") {
    // remove string related operators
    let calcStringOperators = [
      "save as string",
      "save calculated",
    ];
    //funny guy where is other first element! use concat
    //requiredOperators = $.extend(true, operators, calcStringOperators);
    requiredOperators = operators.concat(calcStringOperators);
/*    let nonStringOperators = [
      "save in variable",
    ];
    requiredOperators = $(requiredOperators).not(nonStringOperators).get();*/
  } else if (type == "string") {
    // remove string related operators
    let nonStringOperators = [
      "greater or equals",
      "smaller or equals",
      "smaller",
      "greater",
    ];
    requiredOperators = $(operators).not(nonStringOperators).get();
  } else if (type == "incNull") {
    // ad Null Operators
    let calcStringOperators = [
      'is null',
      'is not null'
    ];
    requiredOperators = operators.concat(calcStringOperators);
  } else if (type == "ipaddr") {
    // remove string related operators
    requiredOperators = operators;
    requiredOperators.push("iprange");
  } else {
    // set default operators
    requiredOperators = operators;
  }
  requiredOperators.sort();
  //console.log(requiredOperators);
  return requiredOperators;
}

function applyPatch(document, patch) {
  var results = new Array(patch.length);
  for (var i = 0, length_1 = patch.length; i < length_1; i++) {
    // we don't need to pass mutateDocument argument because if it was true, we already deep cloned the object, we'll just pass `true`
    try {
      let value = jsonpatch.applyOperation(document, patch[i], false, true, false, i);
      results[i] = value;
      document = results[i].newDocument; // in case root was replaced
    } catch (e) {
      console.log(e);
    }
  }
  results.newDocument = document;



  // clonedtemplate.req.body = jsonpatch.applyPatch(clonedtemplate.req.body, stepStoredValue.req.body).newDocument;
  return results.newDocument;
}


function addEditedRequestValues(stepType, templateValue, testValue, root) {
  root++;

  if (isObjectArray(testValue)) {

    if (!templateValue || Object.keys(templateValue).length == 0) {
      // value does not exist in the template. add it.
      // check for the supressed value and remove them from the testValue
      // as per micheal request. this has been change. now you can supress test case attributes
      let insertedValueArray = [];
      testValue.forEach((testLine) => {
        insertedValueArray.push(testLine);
      });
      templateValue = insertedValueArray;
    } else {
      //console.log(testValue);
      testValue.forEach((testLine, testIndex) => {
        if (testLine.fix == "2") {
          // the value has been suppressed. search for the source
          let searchArray = {};
          $.extend(searchArray, testLine);
          delete searchArray.fix;
          let foundOnce = false;
          if (isObjectArray(templateValue)) {
            // $.each(templateValue, function (templateReqIndex, templateReqValue) {
            templateValue.forEach((templateReqValue, templateReqIndex) => {
              let found = checkIdenticalObjects(searchArray, templateReqValue);
              if (found) {
                foundOnce = true;
                templateReqValue.fix = 2;


                if (isObjectArray(testLine.content)) {
                  $.each(testLine.content, function (reqIndex, reqValue) {
                    if (typeof templateValue[templateReqIndex].content == "object") {
                      templateValue[templateReqIndex].content[reqIndex] = addEditedRequestValues(stepType, templateReqValue.content[reqIndex], reqValue, root);
                    }
                  });
                } else {

                  templateValue[templateReqIndex] = templateReqValue;
                }
                // reordering the object accordiing to the test not the template
                if (stepType != "HTTP") {
                  let storedTemplateValue = templateValue[templateReqIndex]
                  templateValue.splice(templateReqIndex, 1);
                  templateValue.splice(testIndex, 0, storedTemplateValue);
                }
                return;
              }
              //  }
            });
          }
          if (!foundOnce) {
            // not found in the template so add the supressed value
            templateValue.push(testLine);
          }
        } else if (testLine.fix == "0") {
          // add. if the content is array, it means that the contentent is grouped
          if (isObjectArray(testLine.content)) {
            // search for the single value in the templates
            let searchArray = {};
            $.extend(searchArray, testLine);
            delete searchArray.fix;
            delete searchArray.content;
            if (isObjectArray(templateValue)) {
              let found = false;
              for (let [templateReqIndex, templateReqValue] of Object.entries(templateValue)) {
                //  $.each(templateValue, function (templateReqIndex, templateReqValue) {
                found = checkIdenticalObjects(searchArray, templateReqValue);
                // if found  then add all the contents recursively
                if (found) {
                  $.each(testLine.content, function (reqIndex, reqValue) {
                    templateValue[templateReqIndex].content[reqIndex] = addEditedRequestValues(stepType,templateReqValue.content[reqIndex], reqValue, root);
                  });
                  let storedTemplateValue = templateValue[templateReqIndex]
                  templateValue.splice(templateReqIndex, 1);
                  templateValue.splice(testIndex, 0, storedTemplateValue);
                  return;

                }
                // });
              }
              if (!found) {
                // if not found push all
                // templateValue.push(testLine);
                templateValue.splice(testIndex, 0, testLine);
              }
            }
          } else {
            //templateValue.push(testLine);
            templateValue.splice(testIndex, 0, testLine);
          }
        }
      });
    }
  }
  // console.log(templateValue)
  return templateValue;
}
// function addEditedRequestValues(templateValue, testValue, root) {
//   root++;

//   if (isObjectArray(testValue)) {

//     if (!templateValue) {
//       // value does not exist in the template. add it.
//       // check for the supressed value and remove them from the testValue
//       // as per micheal request. this has been change. now you can supress test case attributes
//       let insertedValueArray = [];
//       testValue.forEach((testLine) => {
//         insertedValueArray.push(testLine);
//       });
//       templateValue = insertedValueArray;
//     } else {
//       console.log(testValue);
//       testValue.forEach((testLine) => {
//         if (testLine.fix == "2") {
//           // the value has been suppressed. search for the source
//           let searchArray = {};
//           $.extend(searchArray, testLine);
//           delete searchArray.fix;
//           let foundOnce = false;
//           if (isObjectArray(templateValue)) {
//             // $.each(templateValue, function (templateReqIndex, templateReqValue) {
//             templateValue.forEach((templateReqIndex, templateReqValue) => {
//               let found = checkIdenticalObjects(searchArray, templateReqValue);
//               if (found) {
//                 foundOnce = true;
//                 templateReqValue.fix = 2;


//                 if (isObjectArray(testLine.content)) {
//                   $.each(testLine.content, function (reqIndex, reqValue) {
//                     if (typeof templateValue[templateReqIndex].content == "object") {
//                       templateValue[templateReqIndex].content[reqIndex] = addEditedRequestValues(templateReqValue.content[reqIndex], reqValue, root);
//                     }
//                   });
//                 } else {

//                   templateValue[templateReqIndex] = templateReqValue;
//                 }
//               }
//               //  }
//             });
//           }
//           if (!foundOnce) {
//             // not found in the template so add the supressed value
//             templateValue.push(testLine);
//           }
//         } else if (testLine.fix == "0") {
//           // add. if the content is array, it means that the contentent is grouped
//           if (isObjectArray(testLine.content)) {
//             // search for the single value in the templates
//             let searchArray = {};
//             $.extend(searchArray, testLine);
//             delete searchArray.fix;
//             delete searchArray.content;
//             if (isObjectArray(templateValue)) {
//               let found = false;
//               for (let [templateReqIndex, templateReqValue] of Object.entries(templateValue)) {
//                 //  $.each(templateValue, function (templateReqIndex, templateReqValue) {
//                 found = checkIdenticalObjects(searchArray, templateReqValue);
//                 // if found  then add all the contents recursively
//                 if (found) {
//                   $.each(testLine.content, function (reqIndex, reqValue) {
//                     templateValue[templateReqIndex].content[reqIndex] = addEditedRequestValues(templateReqValue.content[reqIndex], reqValue, root);
//                   });
//                   return false;
//                 }
//                 // });
//               }
//               if (!found) {
//                 // if not found push all
//                 templateValue.push(testLine);
//               }
//             }
//           } else {
//             templateValue.push(testLine);
//           }
//         }
//       });
//     }
//   }
//   return templateValue;
// }

function checkIdenticalObjects(searchArray, searchedObject) {

  var found = false;
  if (isObjectArray(searchArray)) {
    if (typeof searchArray["atRanId"] != "undefined" && typeof searchedObject["atRanId"] != "undefined" && searchArray["atRanId"] == searchedObject["atRanId"] && searchArray["source"] == searchedObject["source"]) {
      //if both has random id and and they are equal then it is the same value return true
      found = true;
    } else {
      $.each(searchArray, function (searchIndex, searchValue) {
        if (searchedObject[searchIndex] && searchedObject[searchIndex] == searchValue) {
          //same value move on
          found = true;
        } else {
          found = false;
          return false;
        }
      });
    }
  }
  return found;
}

function getMandatory(attrvalue, disabled, enableSuppress, centerMed) {
  let mandatoryTd;
  //let fixed="";
  // let suppress="";
  let value = 0;
  let checked = "";
  if (attrvalue.mandatory) {
    if (attrvalue.mandatory == 1) {
      checked = "checked";
      value = 1;
    }
  }
  if (typeof centerMed == "undefined") {
    centerMed = "centerMed";
  }

  if (enableSuppress) {
    mandatoryTd = "<td class='mandatorytd " + centerMed + "'><input type='hidden' class='mandatory' value=" + value + "></td>";
  } else {
    mandatoryTd = "<td class='mandatorytd " + centerMed + "'><input type='checkbox' class='mandatory' " + checked + " " + disabled + "></td>";
  }
  return mandatoryTd;
}

function getSupressContent(attrvalue, suppressDesabled, disabled, enableSuppress) {
  let suppressValue = "";
  //let fixed="";
  let suppress = "";
  if (attrvalue.fix) {
    if (attrvalue.fix == 1) {
      //fixed="checked";
    } else if (attrvalue.fix == 2) {
      suppress = "checked";
    }
  }

  if (enableSuppress) {
    suppressValue = "<input class='suppressCheck' type='checkbox' " + suppress + " " + suppressDesabled + ">";
  }
  return suppressValue;
}

export {
  applyPatch,
  addEditedRequestValues,
  getMandatory,
  getSupressContent,
  getOperators
};